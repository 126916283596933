import Social from "./Social";

export default function Footer() {
  return (
    <footer className="bg-black text-white flex flex-col gap-5 md:flex-row justify-between items-center py-4 px-[30px] md:px-[40px] lg:px-[140px] ">
      <Social />
      <div className="font-medium text-[20px]">
        <a href="https://www.notion.so/nobodyeatsalone/Terms-of-Use-745a39aebee848c880915449fe4828cd">
          Terms of Use
        </a>{" "}
        /{" "}
        <a href="https://www.notion.so/nobodyeatsalone/Privacy-Policy-59963e7017af43229808a6265aec2c7b">
          Privacy Policy
        </a>{" "}
        /{" "}
        <a href="https://nobodyeatsalone.notion.site/Refund-Policy-adf2251effb54d5eb7041f10cf1b491f">
          Refund Policy
        </a>
      </div>
    </footer>
  );
}
