import { ReactComponent as Logowgw } from "../assets/wegowhere.svg"
export default function Navbar() {
  return (
    <nav className="flex flex-row justify-between items-center pt-[30px] px-[30px]  sm:px-8 md:px-[40px] lg:px-[140px] bg-black">
      
      <Logowgw/>
      
      <div className="text-white font-bold">
        <a href="https://link.wegowhere.com/landing-get-app">
          Get App
        </a>
      </div>
    </nav>
  );
}
