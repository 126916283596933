import { ReactComponent as Facebook } from "../assets/Facebook.svg"
import { ReactComponent as Instagram } from "../assets/Instagram.svg"
import { ReactComponent as Twitter } from "../assets/Twitter.svg"

export default function Social() {
  return (
    <div className="flex flex-row items-start">
      <a href="https://www.facebook.com/wegowhereapp">
       
       <Facebook/>
      </a>

      <a href="https://www.instagram.com/wegowhereapp/">
       <Instagram/>
      </a>

      <a href="https://twitter.com/wegowhere">
       <Twitter/>
      </a>
    </div>
  );
}
