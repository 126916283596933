import { ReactComponent as Star } from "../assets/Stars.svg"

export default function Rating(props) {
  const { rate, platform, k } = props;
  return (
    <div className="flex flex-col items-center gap-[15px]">
      <p className="Score font-[850] text-[80px]">{rate}/5</p>
  
     <Star/>

      <div>
        <p className="font-semibold text-[22px] text-center">
          On the {platform} <br></br>
        </p>
        <p className="font-semibold text-center"> {k}k Ratings </p>{" "}
      </div>
    </div>
  );
}
