import { ReactComponent as QR } from "../assets/qr-code.svg"
export default function QRcode() {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="bg-white rounded-[40px] border-black border-[3px] w-[196px] h-auto items-center justify-center ">
        <div className="flex items-center justify-center ">
            <QR className="m-5" />
        </div>
      </div>
      <p className="font-semibold text-center"> Scan to Download</p>
    </div>
  );
}
