import MobileScreenshot from '../assets/mobilescreenshot.png'

export default function Content() {
  return (
    <div className="flex flex-col-reverse items-center md:flex-col-reverse lg:flex-row justify-between gap-[50px] md:gap-2.5">
      <div className="flex flex-col items-start ">
        <p className="Frame text-[60px] leading-[60px] sm:text-[90px] sm:leading-[90px] md:leading-[120px] md:text-[120px] text-white">
          THE
        </p>
        <p className="Frame  text-[60px] leading-[60px] sm:text-[90px] sm:leading-[90px] md:leading-[120px] md:text-[120px]  text-[#FC0889]">
          MEET IRL
        </p>
        <p className="Frame  text-[60px] leading-[60px] sm:text-[90px] sm:leading-[90px] md:leading-[120px]  md:text-[120px]  text-[#49D6D8]">
          SOCIAL
        </p>
        <p className="Frame  text-[60px] leading-[60px] sm:text-[90px] sm:leading-[90px] md:leading-[120px] md:text-[120px]  text-white">
          APP
        </p>
      </div>

      <div className="mobile-screenshot items-center">
        <div>
          <img
            src={MobileScreenshot}
            alt="phone"
            width="507"
            height="559"
          />
        </div>
      </div>
    </div>
  );
}
