import { ReactComponent as Appstore } from "../assets/appstore-badge.svg"
import { ReactComponent as Goolgplay } from "../assets/googleplay-badge.svg"

export default function Download() {
  return (
    <div className="flex flex-row items-center justify-center md:items-center md:justify-center lg:justify-start lg:items-start lg:px-[35px] md:pb-[50px] md:py-[50px] gap-6 ">
      <a href="https://apps.apple.com/th/app/eatmatch/id1458579256">
        <Appstore/>
      </a>

      <a href="https://play.google.com/store/apps/details?id=app.eatmatch.eatmatch">
        <Goolgplay/>
      </a>
    </div>
  );
}
