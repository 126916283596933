import Navbar from "./components/Navbar";
import Content from "./components/Content";
import Download from "./components/Download";
import Tag from "./components/Tag";
import Rating from "./components/Rating";
import QRcode from "./components/Qrcode";
import Footer from "./components/Footer";
import "./App.css";


function App() {
  return (
    <>
      <Navbar />
  
      <section className="bg-black  px-[35px] md:px-[140px] py-[50px] md:py-[10px]">
        <Content />
        <p className="text-white font-bold leading-9 text-[18px] md:text-[20px]  py-[50px] md:py-2 sm:text-center md:text-start">
          Get friends for anything you can't imagine doing alone
        </p>
        <Download />
      </section>

      <section className="bg-[#49D6D8] flex flex-row  items-stretch px-4 py-5 gap-[10px] overflow-x-auto ">
        <Tag name="🍻 Alcohol" />
        <Tag name="📝 Live Music" />
        <Tag name="🍸 Cocktail Bar" />
        <Tag name="🏐 Volleyball" />
        <Tag name="🎲 Boardgame" />
        <Tag name="🍿 Movies" />
        <Tag name="🍣 Omakase" />
        <Tag name="🏸 Badminton" />
        <Tag name="🏀 Basketball" />
        <Tag name="📈 Investing" />
        <Tag name="🎶 Concert" />
      </section>

      <section className="bg-[#F3F3F3] flex flex-col lg:flex-row justify-between items-center gap-[30px] lg:gap-[100px] py-[66px] md:px-[40px] lg:px-[144px]">
        <Rating rate="4.6" platform="App Store" k="2.1" />
        <Rating rate="4.9" platform="Google Play" k="1.4" />
        <QRcode />
      </section>

      <Footer />
    </>
  );
}

export default App;
